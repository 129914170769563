import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { colors } from '../../theme';

const Link = props => {
  const { href, to, decoration } = props;

  const css = decoration && {
    textDecoration: 'underline',
    color: colors.brandColor
  };

  if (href) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a css={css} {...props} />;
  }

  if (!to) {
    return <span css={css} {...props} />;
  }

  return <GatsbyLink css={css} {...props} />;
};

export default Link;
