/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from 'react';
import { fontSizes, fontWeights, colors, mq } from '../../theme';
import ClickOut from '../ClickOut';
import Link from '../Link';

const HeaderLink = ({ label, sub, subSub, noLink, expanded, ...rest }) => {
  const TagLink = noLink ? 'span' : Link;

  let expandedElement = null;
  if (expanded !== undefined) {
    expandedElement = (
      <div
        css={{
          flex: 1,
          textAlign: 'right',
          color: colors.brandColor,
          fontSize: 25
        }}
      >
        {expanded ? '-' : '+'}
      </div>
    );
  }

  return (
    <div css={{ zIndex: 1, marginTop: 20 }}>
      <TagLink
        css={{
          display: 'flex',
          alignItems: 'center',
          fontSize: subSub ? fontSizes.regular : fontSizes.medium,
          // eslint-disable-next-line no-nested-ternary
          fontWeight: sub
            ? fontWeights.medium
            : subSub
            ? fontWeights.regular
            : fontWeights.semiBold,
          lineHeight: subSub ? '20px' : '28px',
          color: sub ? colors.neutralLight : colors.neutralWhite,
          whiteSpace: 'nowrap'
        }}
        {...rest}
      >
        {label}
        {expandedElement}
      </TagLink>
    </div>
  );
};

const MenuMobile = () => {
  const [opened, updateOpened] = useState(false);
  const [subOpen, setSubOpen] = useState(null);

  useEffect(() => {
    return () => {
      closeMenu();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openMenu = () => {
    if (opened) {
      return;
    }

    updateOpened(true);
    document.body.classList.add('modal-open');
    window.scrollTo(0, 0);
  };

  const closeMenu = () => {
    if (!opened) {
      return;
    }

    updateOpened(false);
    document.body.classList.remove('modal-open');
  };
  return (
    <div
      css={mq({
        display: ['none', 'flex'],
        borderRight: `2px solid ${colors.neutral}`,
        paddingRight: opened ? 14 : 20
      })}
    >
      <div
        onClick={openMenu}
        role="button"
        onKeyPress={openMenu}
        css={{
          marginLeft: 10,
          marginTop: 20
        }}
      >
        <div
          css={{
            width: '2rem',
            height: 0,
            borderRadius: '10px',
            transition: 'all 0.3s linear',
            position: 'relative',
            marginBottom: '5px',
            borderTop: `0.125rem solid ${colors.neutral}`,
            borderBottom: `0.125rem solid ${colors.neutral}`,
            borderColor: opened ? 'transparent' : colors.neutral,
            ':before': {
              position: 'absolute',
              content: `" "`,
              width: opened ? '2rem' : '2.5rem',
              height: '0.25rem',
              background: colors.neutral,
              borderRadius: '10px',
              transition: 'all 0.3s linear',
              top: 10,
              left: 0,
              transform: opened ? 'rotate(-45deg)' : null,
              transformOrigin: 'left top '
            },
            ':after': {
              position: 'absolute',
              content: `" "`,
              width: opened ? '2rem' : '2.5rem',
              height: '0.25rem',
              background: colors.neutral,
              borderRadius: '10px',
              transition: 'all 0.3s linear',
              bottom: 10,
              left: 0,
              transform: opened ? 'rotate(45deg)' : null,
              transformOrigin: 'left bottom'
            }
          }}
        />
        {opened ? (
          <p css={{ color: colors.neutral, paddingTop: 10 }}>fermer</p>
        ) : (
          <p css={{ color: colors.neutral, paddingTop: 10 }}>menu</p>
        )}
      </div>
      <ClickOut onClickOut={closeMenu}>
        <div
          css={{
            position: 'fixed',
            display: 'flex',
            overflowY: 'auto',
            flexDirection: 'column',
            top: 80,
            right: 0,
            bottom: 0,
            left: 0,
            padding: '0 18px',
            paddingBottom: 18,
            transition:
              'transform 200ms ease-in-out, opacity 200ms ease-in-out',
            transform: !opened && 'translate3d(-100vw, 0, 0)',
            opacity: !opened && 0,
            backgroundColor: colors.neutralDarker,
            backdropFilter: 'blur(6px)',
            zIndex: 999
          }}
        >
          <HeaderLink onClick={closeMenu} to="/" label="Accueil" />
          <HeaderLink
            noLink
            expanded={subOpen === 'solutions'}
            onClick={() => {
              setSubOpen(subOpen === 'solutions' ? null : 'solutions');
            }}
            label="Solutions"
          />
          {subOpen === 'solutions' && (
            <div css={{ zIndex: 1, marginLeft: 20 }}>
              <HeaderLink
                sub
                onClick={closeMenu}
                to="/fonctionnalites-logiciel/"
                label="Logiciel Web2vi"
              />
              <HeaderLink
                sub
                onClick={closeMenu}
                to="/drone-metre3D-batiment/"
                label="Métrés 3D par drone"
              />
              <HeaderLink
                sub
                onClick={closeMenu}
                to="/bibliotheque-ouvrages-chiffrage/"
                label="Bibliothèque d’ouvrage"
              />
            </div>
          )}
          <HeaderLink onClick={closeMenu} to="/tarifs/" label="Tarifs" />
          <HeaderLink
            noLink
            expanded={subOpen === 'apropos'}
            onClick={() => {
              setSubOpen(subOpen === 'apropos' ? null : 'apropos');
            }}
            label="À propos"
          />
          {subOpen === 'apropos' && (
            <div css={{ zIndex: 1, marginLeft: 20 }}>
              <HeaderLink
                onClick={closeMenu}
                sub
                to="/equipe/"
                label="L’équipe Web2vi"
              />
              <HeaderLink
                noLink
                sub
                to="/partenaires/#tab-partenaires"
                label="Partenaires"
              />
              <div css={{ zIndex: 1, marginLeft: 20 }}>
                <HeaderLink
                  subSub
                  onClick={closeMenu}
                  to="/partenaires/parrot/"
                  label="Parrot drone"
                />
                <HeaderLink
                  subSub
                  onClick={closeMenu}
                  to="/partenaires/#tab-partenaires"
                  label="Les marques partenaires"
                />
                <HeaderLink
                  subSub
                  onClick={closeMenu}
                  to="/partenaires/#tab-metiers"
                  label="Les métiers"
                />
              </div>
              <HeaderLink
                onClick={closeMenu}
                sub
                to="/temoignages/"
                label="Témoignages"
              />
              <HeaderLink
                onClick={closeMenu}
                sub
                to="/foire-aux-questions/"
                label="FAQ"
              />
            </div>
          )}
          <HeaderLink
            onClick={closeMenu}
            href="https://blog.web2vi.com"
            label="Blog"
          />
          <HeaderLink
            onClick={closeMenu}
            href="https://web2vi.app/login"
            label="Espace client"
          />
        </div>
      </ClickOut>
    </div>
  );
};

export default MenuMobile;
