// nouveau theme pour chakra
import { extendTheme } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import 'focus-visible/dist/focus-visible';

// Theme par default: https://chakra-ui.com/docs/theming/theme

const fonts = {
  body: 'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  heading: 'inherit'
};

const fontSizes = {
  xxxs: '0.5rem', // 8px
  xxs: '0.625rem', // 10px
  xs: '0.75rem', // 12px
  sm: '0.8125rem', // 13px
  md: '0.875rem', // 14px
  lg: '1rem', // 16px
  xl: '1.125rem', // 18px
  '2xl': '1.5rem', // 24px
  '3xl': '1.75rem', // 28px
  '4xl': '2rem', // 32px
  '5xl': '2.25rem', // 36px
  '6xl': '3rem', // 48px
  '7xl': '3.75rem', // 60px
  '8xl': '4.5rem', // 72px
  '9xl': '6rem' // 96px
};

const colors = {
  brand: {
    50: '#fff7e7',
    100: '#fdefce',
    200: '#fde7b6',
    300: '#fcd34d',
    400: '#fbc218',
    500: '#fba818',
    600: '#e76f21',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
    950: '#39251a'
  },
  gray: {
    50: '#f8f9fa',
    100: '#f4f6f7',
    200: '#dde1e4',
    300: '#c6cccf',
    400: '#afb7bb',
    500: '#79878d',
    600: '#555e62',
    700: '#485054',
    800: '#2f3437',
    900: '#18191b'
  },
  success: {
    // Tailwindcss Lime
    50: '#f7fee7',
    100: '#ecfccb',
    200: '#d9f99d',
    300: '#bef264',
    400: '#a3e635',
    500: '#84cc16',
    600: '#65a30d',
    700: '#4d7c0f',
    800: '#3f6212',
    900: '#365314'
  },
  danger: {
    // Tailwindcss Red
    50: '#fef2f2',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#f87171',
    500: '#ef4444',
    600: '#dc2626',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d'
  },
  info: {
    50: '#EFF7FF',
    100: ' #DDEDFE',
    200: ' #BDDFFD',
    300: ' #8BCAFC',
    400: ' #5CAAF5',
    500: ' #2D8BEE',
    600: ' #1A6DDF',
    700: ' #1457C6',
    800: ' #1648A1',
    900: ' #183F80'
  },
  blackAlpha: {
    750: 'rgba(0, 0, 0, 0.7)'
  }
};

const rowTransitions = {
  update: keyframes`
  0%{
    background-color: white;
    opacity: 0.01;
    transform: translate(-40px, 0)
  }
  100% {
    opacity: 1;
    background-color: white;
    transform: translate(0, 0);
    transition: all 500ms ease-in;
  }
`,
  remove: keyframes`
  0%{
    opacity: 1;
    background-color: white;
    transform: translate(0, 0)
  }
  100% {
    opacity: 0.2;
    background-color: white;
    transform: translate(40px, 0);
    transition: all 500ms ease-in;
  }
`
};

export const app = extendTheme({
  borders: {
    red: '1px dashed Red',
    lime: '1px dashed Lime'
  },
  letterSpacings: {
    wider: '0.04em'
  },
  fonts,
  fontSizes,
  colors: {
    ...colors,
    red: colors.danger,
    green: colors.success,
    orange: colors.brand,
    blue: colors.info
  },
  shadows: {
    // dark-lg custom : partir de LG, sertir et foncer légèrement
    'dark-lg':
      '0 10px 15px -3px rgb(0 0 0 / 16%), 0 4px 6px -2px rgb(0 0 0 / 10%), rgb(0 0 0 / 2%) 0px 0px 0px 1px',
    outline: `0 0 0 3px ${colors.brand['400']}aa`,
    cellFocus: `inset 0 0 0 1px ${colors.brand['400']}, 0 0 0 1px ${colors.brand['400']}`,
    cellError: `inset 0 0 0 1px ${colors.danger['500']}, 0 0 0 1px ${colors.danger['500']}`,
    soft: '0 10px 20px -3px rgb(0 0 0 / 6%), 0 3px 8px -2px rgb(0 0 0 / 5%)'
  },
  sizes: {
    container: {
      '2xl': '1536px'
    }
  },
  styles: {
    global: props => ({
      ':root, html': {
        bg: props.colorMode === 'dark' ? 'gray.900' : 'gray.100'
      },
      body: {
        fontFamily: 'body',
        color: props.colorMode === 'dark' ? 'gray.50' : 'gray.900',
        bg: 'inherit !important',
        lineHeight: 'base',
        fontSize: 'md'
      },
      '*::placeholder': {
        color: 'blackAlpha.300'
      },
      button: {
        // When a text element (like Link) is as='button'
        fontWeight: 'inherit',
        fontStyle: 'inherit'
      },
      'button, button:focus, input, input:focus': {
        outline: 'none'
      },
      '.data-grid-view-action-update': {
        animation: `${rowTransitions.update} 800ms normal forwards ease`
      },
      '.data-grid-view-action-remove': {
        animation: `${rowTransitions.remove} 800ms normal forwards ease`
      },
      h4: {
        fontWeight: 'semibold',
        fontSize: 'xl'
      },
      h2: {
        fontWeight: 'bold'
      },
      strong: {
        fontWeight: 'semibold'
      },
      '[data-js-focus-visible] .focus-allowed *:focus, [data-js-focus-visible] .focus-visible-added:focus':
        {
          // les éléments avec '.focus-allowed' et les enfants auront un focus normal, et pas seulement le focus-visible
          boxShadow: 'var(--chakra-shadows-outline);'
        }
    })
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'medium'
      },
      defaultProps: {
        variant: 'solid',
        colorScheme: 'brand'
      },
      variants: {
        solid: props => ({
          color: 'blackAlpha.900',
          bg:
            props.colorScheme === 'gray'
              ? 'gray.200'
              : `${props.colorScheme}.400`,
          _hover: {
            color: 'black',
            bg:
              props.colorScheme === 'gray'
                ? 'gray.300'
                : `${props.colorScheme}.500`
          },
          _active: {
            color: 'black',
            bg:
              props.colorScheme === 'gray'
                ? 'gray.400'
                : `${props.colorScheme}.600`
          }
        }),
        outline: props => ({
          bg: props.colorMode === 'light' ? 'white' : 'gray.800',
          borderColor: props.colorScheme === 'gray' ? 'gray.700' : undefined
        }),
        ghost: props => ({
          color: props.colorScheme === 'gray' ? 'gray.700' : undefined
        })
      }
    },
    Checkbox: {
      defaultProps: {
        colorScheme: 'brand'
      },
      baseStyle: {
        container: {
          borderColor: 'blackAlpha.300'
        }
      }
    },
    EditableInput: {
      variants: {
        blueprint: {
          field: {
            focusBorderColor: 'blue.500'
          }
        }
      }
    },
    FormLabel: {
      variants: {
        simple: {},
        caps: {
          fontSize: 'xs',
          textTransform: 'uppercase',
          letterSpacing: 'wide'
        }
      }
    },
    Link: {
      defaultProps: {
        color: 'brand.600'
      },
      baseStyle: props => ({
        color: props.color
      })
    },
    Menu: {
      baseStyle: {
        list: {
          boxShadow: 'lg',
          color: 'gray.900'
        },
        item: {
          px: 4,
          minH: 10,
          _focus: {
            bg: 'brand.50'
          },
          _active: {
            bg: 'brand.100'
          },
          svg: {
            width: 5,
            height: 5
          }
        }
      }
    },
    NumberInput: {
      defaultProps: {
        focusBorderColor: 'brand.400'
      },
      variants: {
        outline: props => ({
          field: {
            bg: props.colorMode === 'light' ? 'gray.50' : 'blackAlpha.400',
            color:
              props.colorMode === 'light' ? 'blackAlpha.750' : 'whiteAlpha.800',
            borderColor:
              props.colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300',
            fontWeight: 'medium'
          }
        })
      }
    },
    Input: {
      defaultProps: {
        focusBorderColor: 'brand.400'
      },
      variants: {
        outline: props => ({
          field: {
            bg: props.colorMode === 'light' ? 'gray.50' : 'blackAlpha.400',
            color:
              props.colorMode === 'light' ? 'blackAlpha.750' : 'whiteAlpha.800',
            borderColor:
              props.colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300',
            fontWeight: 'medium',

            '&[readonly]': {
              bg: props.colorMode === 'light' ? 'gray.200' : 'blackAlpha.300'
            }
          }
        })
      }
    },
    Spinner: {
      defaultProps: {
        label: 'Chargement…',
        speed: '.75s',
        color: 'inherit',
        size: 'lg'
      }
    },
    Tabs: {
      defaultProps: {
        colorScheme: 'brand',
        focusBorderColor: 'brand.400',
        size: 'lg'
      },
      variants: {
        line: {
          tab: {
            fontWeight: 'medium',
            color: 'gray.500',
            _selected: {
              bg: 'white'
            }
          },
          tablist: {
            borderColor: 'blackAlpha.200'
          },
          tabpanel: {
            p: 0
          }
        }
      },
      sizes: {
        md: {
          tab: {
            fontSize: 'md'
          }
        },
        lg: {
          tab: {
            fontSize: 'lg'
          }
        }
      }
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: 'brand.400'
      },
      variants: {
        outline: props => ({
          lineHeight: 'base',
          bg: props.colorMode === 'light' ? 'gray.50' : 'blackAlpha.400',
          color:
            props.colorMode === 'light' ? 'blackAlpha.750' : 'whiteAlpha.800',
          borderColor:
            props.colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300',
          fontWeight: 'medium',

          '&[readonly]': {
            bg: props.colorMode === 'light' ? 'gray.200' : 'blackAlpha.300'
          }
        })
      }
    }
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  }
});

/* les BG sur root, html et body pour contrer ce mysterieux style :
  <style type="text/css" id="sgqInjectCss" class="sgqInjectMark">body {
      background: #e9eaeb;
  }</style>
*/
