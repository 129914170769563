import React, { useEffect } from 'react';

const defaultRenderContainer = ({ container, children }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={event => {
        event.stopPropagation();
      }}
      ref={container}
    >
      {children}
    </div>
  );
};

const ClickOut = ({
  children,
  renderContainer = defaultRenderContainer,
  onClickOut
} = {}) => {
  const container = React.createRef();

  const handleClickOut = event => {
    if (container.current && !container.current.contains(event.target)) {
      onClickOut(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOut, false);

    return () => {
      document.removeEventListener('click', handleClickOut, false);
    };
  });

  return renderContainer({ container, children });
};

export default ClickOut;
