import React from 'react';
import { colors, fontSizes, fontWeights, mq } from '../../theme';
import Link from '../Link';
import Image from '../Image';

const FooterListHeader = ({ children }) => {
  return (
    <h3
      css={mq({
        marginBottom: [29, 18],
        color: colors.neutralWhite,
        textTransform: 'uppercase',
        fontSize: fontSizes.large,
        textAlign: ['left', 'center'],
        letterSpacing: 1
      })}
    >
      {children}
    </h3>
  );
};

const FooterList = ({ children }) => {
  return (
    <ul
      css={{
        margin: 0,
        padding: 0,
        listStyle: 'none'
      }}
    >
      {children}
    </ul>
  );
};

const FooterListItem = ({ label, ...rest }) => {
  return (
    <li
      css={{ marginBottom: 18, fontSize: fontSizes.medium, lineHeight: '20px' }}
    >
      <Link css={{ color: colors.neutral }} {...rest}>
        {label}
      </Link>
    </li>
  );
};

const Footer = () => (
  <>
    <footer
      css={{
        color: colors.neutralWhite,
        background: colors.neutralDarker
      }}
    >
      <div
        css={mq({
          display: 'flex',
          margin: `0 auto`,
          padding: ['35px 44px 35px 25px'],
          maxWidth: 1376,
          flexDirection: ['row', 'column', 'column'],
          alignItems: 'center'
        })}
      >
        <div
          css={mq({
            flex: 1,
            display: 'flex',
            paddingRight: [99, 0],
            marginBottom: [0, 45, 45],
            flexDirection: 'column',
            textAlign: ['left', 'center', 'center'],
            justifyContent: 'center',
            alignItems: ['auto', 'center', 'center']
          })}
        >
          <Image
            src="logo-header.svg"
            alt="Logo Web2vi"
            css={{ marginBottom: 21, width: 171, height: 37 }}
          />

          <p
            css={mq({
              textTransform: 'uppercase',
              fontSize: fontSizes.medium,
              fontWeight: fontWeights.semiBold,
              letterSpacing: 1,
              lineHeight: '20px'
            })}
          >
            L’outil de pilotage pour
            <br />
            les artisans du bâtiment
          </p>
        </div>

        <div
          css={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            css={mq({
              display: 'flex',
              flexDirection: ['row', 'column'],
              textAlign: ['left', 'center'],
              marginRight: [-148, 0]
            })}
          >
            <div css={mq({ marginRight: [99, 0] })}>
              <FooterListHeader>Solutions</FooterListHeader>
              <FooterList>
                <FooterListItem
                  to="/fonctionnalites-logiciel/"
                  label="Logiciel Web2vi"
                />
                <FooterListItem
                  to="/drone-metre3D-batiment/"
                  label="Métré 3D par drone"
                />
                <FooterListItem
                  to="/bibliotheque-ouvrages-chiffrage/"
                  label="Bibliothèque d’ouvrages"
                />
              </FooterList>
            </div>
            <div css={mq({ marginRight: [99, 0] })}>
              <FooterListHeader>Tarifs</FooterListHeader>
              <FooterList>
                <FooterListItem to="/tarifs/" label="Nos tarifs" />
                <FooterListItem
                  href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
                  label="Démo"
                />
                <FooterListItem
                  href="https://web2vi.app/register"
                  label="Essai gratuit"
                />
              </FooterList>
            </div>
            <div>
              <FooterListHeader>A propos</FooterListHeader>
              <div
                css={mq({ display: 'flex', flexDirection: ['row', 'column'] })}
              >
                <div css={mq({ marginRight: [66, 0] })}>
                  <FooterList>
                    <FooterListItem
                      href="https://info.web2vi.com/contact"
                      label="Contact"
                    />
                    <FooterListItem href="/#next-webinar" label="Webinars" />
                    <FooterListItem to="/temoignages/" label="Témoignages" />
                  </FooterList>
                </div>
                <div css={mq({ marginRight: [66, 0] })}>
                  <FooterList>
                    <FooterListItem to="/foire-aux-questions/" label="FAQ" />
                    <FooterListItem to="/equipe/" label="L’équipe" />
                    <FooterListItem
                      to="/partenaires/parrot/"
                      label="Parrot drone"
                    />
                  </FooterList>
                </div>
                <div css={mq({ marginRight: [120, 0] })}>
                  <FooterList>
                    <FooterListItem
                      to="/partenaires/#tab-partenaires"
                      label="Les marques partenaires"
                    />
                    <FooterListItem
                      to="/partenaires/#tab-metiers"
                      label="Les métiers"
                    />
                    <FooterListItem
                      to="/mentions-legales/"
                      label="Mentions légales"
                    />
                  </FooterList>
                </div>
              </div>
            </div>
          </div>
          <div>
            <FooterListHeader>
              Retrouvez-nous sur les réseaux sociaux
            </FooterListHeader>
            <div
              css={mq({
                display: 'flex',
                alignItems: 'center',
                justifyContent: ['normal', 'center'],
                marginRight: -20
              })}
            >
              {[
                {
                  link: 'https://www.facebook.com/web2vi/',
                  image: 'network/facebook.svg'
                },
                {
                  link: 'https://www.twitter.com/Web2vi',
                  image: 'network/twitter.svg'
                },
                {
                  link: 'https://www.linkedin.com/company/10931642',
                  image: 'network/linkedin.svg'
                },
                {
                  link: 'https://www.instagram.com/web2vi/',
                  image: 'network/instagram.svg'
                },
                {
                  link: 'https://www.youtube.com/channel/UCzdHSdsYCgxHOWeznb7sfUQ',
                  image: 'network/youtube.svg'
                }
              ].map(({ link, image }) => (
                <Link
                  key={image}
                  target="_blank"
                  href={link}
                  css={{ marginRight: 20 }}
                >
                  <Image
                    css={{
                      width: 20,
                      filter: 'invert(0.6)',
                      transition: 'filter 300ms ease-in-out',
                      ':hover': {
                        filter: 'invert(1)'
                      }
                    }}
                    src={image}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        css={mq({
          padding: '30px 18px',
          backgroundColor: colors.neutralBlack30,
          textAlign: ['center', 'left']
        })}
      >
        <p
          css={mq({
            lineHeight: ['28px', '22px'],
            fontSize: [fontSizes.semiBig, fontSizes.medium],
            fontWeight: fontWeights.bold,
            color: colors.neutralWhite20,
            textTransform: 'uppercase'
          })}
        >
          Copyright {new Date().getUTCFullYear()} web2vi.com.
          <span css={mq({ display: ['none', 'inline-block'] })}>
            <br />
          </span>{' '}
          Tous droits réservés.
        </p>
      </div>
    </footer>
  </>
);

export default Footer;
