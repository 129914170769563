import { colors, mq } from '../../theme';
import React from 'react';
import Menu from './Menu';
import MenuMobile from './MenuMobile';
import Image from '../Image';
import WebsiteButton from '../WebsiteButton';
import ScrollToTop from '../ScrollToTop';

const Header = () => (
  <div
    css={mq({
      display: 'block',
      height: 80,
      backgroundColor: colors.neutralDarker
    })}
  >
    <header
      css={mq({
        display: 'flex',
        height: [80, 80, 120],
        flexDirection: ['row', 'column', 'column'],
        background: colors.neutralDarker,
        position: ['initial', 'fixed'],
        left: 0,
        right: 0,
        top: 0,
        zIndex: 100,
        width: ['90%', '100%'],
        margin: ['auto', '', '']
      })}
    >
      <ScrollToTop />
      <div
        css={mq({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: [80, 90],
          flex: 1
        })}
      >
        <MenuMobile />
        <Image
          onClick={() => {
            document.location.href = '/';
          }}
          alt="logo"
          css={mq({
            cursor: 'pointer',
            display: ['inherit', 'none'],
            margin: '20px 45px 23px',
            width: 170,
            height: 37
          })}
          src="logo-header.svg"
        />
        <div css={mq({ zIndex: 3, display: ['none', 'block'] })}>
          <Image
            onClick={() => {
              document.location.href = '/';
            }}
            alt="logo"
            css={{
              cursor: 'pointer',
              margin: '6px 12px',
              width: 170
            }}
            src="full-logo-header.svg"
          />
        </div>
        <WebsiteButton
          width={100}
          mr={2}
          borderRadius="full"
          display={{ base: 'inline-block', md: 'none' }}
          href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
        >
          démo
        </WebsiteButton>
        <Menu />
      </div>
      <WebsiteButton
        width={200}
        height="60%"
        fontSize={12}
        borderRadius="full"
        margin="auto"
        display={{ base: 'none', md: 'inline-block' }}
        href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
      >
        Demander une démo
      </WebsiteButton>
    </header>
  </div>
);

export default Header;
