import React from 'react';
import { fontSizes, fontWeights, colors, mq } from '../../theme';
import Link from '../Link';

const HeaderLink = ({ children, ...rest }) => {
  return (
    <div
      {...rest}
      css={{
        position: 'relative',
        marginRight: 33,
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.medium,
        lineHeight: '80px',
        cursor: 'pointer',
        color: colors.neutral,
        whiteSpace: 'nowrap',
        '&:hover': {
          '& > a': {
            transition: 'color 200ms ease-in-out',
            color: colors.brandColor
          },
          '> div': {
            opacity: 1,
            visibility: 'visible'
          }
        }
      }}
    >
      {children}
    </div>
  );
};

const Menu = () => {
  return (
    <div
      css={mq({
        position: 'relative',
        display: ['flex', 'none'],
        flex: 1,
        marginRight: 33,
        justifyContent: 'flex-end',
        '& > div:last-child': {
          marginRight: 0
        }
      })}
    >
      <HeaderLink>
        <Link
          css={{
            fontSize: fontSizes.large
          }}
          to="/"
        >
          Accueil
        </Link>
      </HeaderLink>
      <HeaderLink>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          css={{
            fontSize: fontSizes.large
          }}
        >
          Solutions
        </a>
        <div
          css={{
            transition: 'all 300ms ease-in-out',
            visibility: 'hidden',
            opacity: 0,
            position: 'absolute',
            backgroundColor: colors.neutralDarker,
            zIndex: 4,
            top: 80,
            marginLeft: '-24px',
            '& > a': {
              display: 'block',
              padding: '0px 26px',
              boxSizing: 'border-box',
              height: 40,
              lineHeight: '40px',

              ':hover': {
                color: colors.neutralWhite,
                backgroundColor: colors.brandColor
              }
            }
          }}
        >
          <Link href="/fonctionnalites-logiciel/">Logiciel Web2vi</Link>
          <Link to="/drone-metre3D-batiment/">Métrés 3D par drone</Link>
          <Link to="/bibliotheque-ouvrages-chiffrage/">
            Bibliothèque d’ouvrages
          </Link>
        </div>
      </HeaderLink>
      <HeaderLink>
        <Link
          css={{
            fontSize: fontSizes.large
          }}
          to="/tarifs/"
        >
          Tarifs
        </Link>
      </HeaderLink>
      <HeaderLink>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          css={{
            fontSize: fontSizes.large
          }}
        >
          À propos
        </a>
        <div
          css={{
            transition: 'all 300ms ease-in-out',
            visibility: 'hidden',
            opacity: 0,
            position: 'absolute',
            backgroundColor: colors.neutralDarker,
            zIndex: 4,
            top: 80,
            '& > a': {
              display: 'block',
              padding: '0px 14px',
              boxSizing: 'border-box',
              height: 40,
              lineHeight: '40px',

              ':hover': {
                color: colors.neutralWhite,
                backgroundColor: colors.brandColor
              }
            }
          }}
        >
          <Link to="/equipe/">L’équipe Web2vi</Link>
          <div
            css={{
              position: 'relative',
              '&:hover': {
                color: colors.neutralWhite,
                backgroundColor: colors.brandColor,
                '& > div': {
                  opacity: 1,
                  visibility: 'visible'
                }
              },
              '& > a': {
                display: 'block',
                padding: '0px 14px',
                boxSizing: 'border-box',
                height: 40,
                lineHeight: '40px',

                '&:hover': {
                  color: colors.neutralWhite,
                  backgroundColor: colors.brandColor
                }
              }
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#">Partenaires</a>

            <div
              css={{
                transition: 'all 300ms ease-in-out',
                visibility: 'hidden',
                opacity: 0,
                position: 'absolute',
                backgroundColor: colors.neutralDarker,
                zIndex: 4,
                top: 0,
                left: '100%',
                '& > a': {
                  color: colors.neutral,
                  display: 'block',
                  padding: '0px 14px',
                  boxSizing: 'border-box',
                  height: 40,
                  lineHeight: '40px',

                  ':hover': {
                    color: colors.neutralWhite,
                    backgroundColor: colors.brandColor
                  }
                }
              }}
            >
              <Link to="/partenaires/parrot/">Parrot drone</Link>
              <Link to="/partenaires/#tab-partenaires">
                Les marques partenaires
              </Link>
              <Link to="/partenaires/#tab-metiers">Les métiers</Link>
            </div>
          </div>
          <Link to="/temoignages/">Témoignages</Link>
          <Link to="/foire-aux-questions/">FAQ</Link>
        </div>
      </HeaderLink>
      <HeaderLink>
        <a
          css={{
            fontSize: fontSizes.large
          }}
          href="https://blog.web2vi.com/"
        >
          Blog
        </a>
      </HeaderLink>
      <HeaderLink>
        <a
          css={{
            fontSize: fontSizes.large
          }}
          href="https://web2vi.app/login"
        >
          Espace client
        </a>
      </HeaderLink>
    </div>
  );
};

export default Menu;
