import { css, keyframes } from '@emotion/react';
import facepaint from 'facepaint';
import { useMediaQuery } from 'react-responsive';

export const breakpoints = {
  mobile: 768,
  tablet: 992,
  desktop: 1200
};

export const mq = facepaint([
  `@media (max-width: ${breakpoints.mobile - 1}px)`,
  `@media (min-width: ${breakpoints.mobile}px) and (max-width: ${
    breakpoints.tablet - 1
  }px)`
]);

export const MediaQuery = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.tablet });
  const isTablet = useMediaQuery({
    minWidth: breakpoints.mobile,
    maxWidth: breakpoints.tablet - 1
  });

  const isMobile = useMediaQuery({ maxWidth: breakpoints.mobile - 1 });

  return children({
    isDesktop,
    isTablet,
    isMobile
  });
};

export const colors = {
  neutralBlack: '#000000',
  neutralBlack10: 'rgba(0, 0, 0, 0.1)',
  neutralBlack20: 'rgba(0, 0, 0, 0.2)',
  neutralBlack30: 'rgba(0, 0, 0, 0.3)',
  neutralBlack70: 'rgba(0, 0, 0, 0.7)',
  neutralDarker: '#2f3437',
  neutralDark: '#79878d',
  neutralDark70: 'rgba(121, 135, 141, 0.7)',
  neutralDark30: 'rgba(121, 135, 141, 0.3)',
  neutralGray: '#ebedef',
  neutral: '#afb7bb',
  neutral20: 'rgba(175, 183, 187, 0.2)',
  neutral30: 'rgba(175, 183, 187, 0.3)',
  neutralLight: '#dde1e4',
  neutralLight70: '#dde1e4',
  neutralLight30: '#f4f6f7',
  neutralLighter: '#f8f9fa',
  neutralWhite: '#ffffff',
  neutralWhite50: 'rgba(255, 255, 255, 0.5)',
  neutralWhite20: 'rgba(255, 255, 255, 0.2)',
  neutralRed: '#FFF3F3',

  brandDarkerColor: 'rgba(251,168,24,1)',
  brandColor: '#fbc218',
  brandColor30: 'rgba(246, 173, 11, 0.3)',
  brandColor20: 'rgba(246, 173, 11, 0.2)',
  brandColor10: 'rgba(246, 173, 11, 0.1)',
  brandColor50: '#fade98',

  accentColor: '#e76f21',
  accentColor80: 'rgba(231, 111, 33, 0.8)',

  hightlightRed: '#d74747',
  hightlightRed10: '#faeeee',
  hightlightRed30: '#f2c6c7',
  hightlightRed70: '#e06f6e',
  hightlightGreen: '#89ba05',
  hightlightGreen10: '#f4f8ea',
  hightlightGreen30: '#dceab4',
  hightlightBlue: '#46b9c2',
  hightlightBlue10: '#9ed9de87',
  hightlightBlue50: '#9ed9de'
};

export const fontFamilies = {
  poppins: 'Poppins'
};

export const fontSizes = {
  smaller: 8,
  small: 10,
  regular: 12,
  medium: 14,
  semiBig: 16,
  large: 18,
  larger: 24,
  big: 28,
  bigger: 32
};

export const fontWeights = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
};

export const devicesWidth = {
  phone: 480,
  mediumTablet: 768
};

export const devices = {
  phone: `@media (max-width:${devicesWidth.phone}px)`,
  mediumTablet: `@media (max-width:${devicesWidth.mediumTablet}px)`,
  breakpoint: `@media (max-width: 1024px)`
};

const rowTransitions = {
  update: keyframes`
  0%{
    background-color: white;
    opacity: 0.01;
    transform: translate(-40px, 0)
  }
  100% {
    opacity: 1;
    background-color: white;
    transform: translate(0, 0);
    transition: all 500ms ease-in;
  }
`,
  remove: keyframes`
  0%{
    opacity: 1;
    background-color: white;
    transform: translate(0, 0)
  }
  100% {
    opacity: 0.2;
    background-color: white;
    transform: translate(40px, 0);
    transition: all 500ms ease-in;
  }
`
};

export const globalStyles = css`
  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: ${colors.neutralLighter};
  }

  button {
    border: none;
    cursor: pointer;
  }

  button,
  button:focus,
  input,
  input:focus {
    outline: none;
  }

  .modal-open {
    overflow: hidden;
  }

  #root,
  #___gatsby {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${fontSizes.regular}px;
    line-height: ${fontSizes.large}px;
    font-family: ${fontFamilies.poppins}, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    line-height: initial;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${fontFamilies.poppins}, sans-serif;
    font-weight: ${fontWeights.bold};
    color: ${colors.neutralDarker};
  }

  h1 {
    margin: 0 0 16px 0;
    font-size: ${fontSizes.bigger}px;
    line-height: 48px;
  }

  h2 {
    font-size: ${fontSizes.big}px;
    line-height: 32px;

    @media (max-width: ${breakpoints.mobile - 1}px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h3 {
    font-size: ${fontSizes.larger}px;
    line-height: 38px;
  }

  h4 {
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.large}px;
    line-height: 28px;
  }

  .data-grid-view-action-update {
    animation: ${rowTransitions.update} 800ms normal forwards ease;
  }

  .data-grid-view-action-remove {
    animation: ${rowTransitions.remove} 800ms normal forwards ease;
  }
`;
